/**
* CONTENTS
*
* IMPORT-PARTIALS
*/
/*------------------------------------*\
 #IMPORT-PARTIALS
\*------------------------------------*/
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

/*body {
  line-height: 1;
}*/
ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/**
* CONTENTS
*
* BREAKPOINT
*
* COLOR
*
* FONT-FAMILY
*
* FONT-SIZE
*
* TRANSITION
*
*/
/*------------------------------------*\
  #BREAKPOINT
\*------------------------------------*/
/*------------------------------------*\
  #COLOR
\*------------------------------------*/
/*------------------------------------*\
  #FONT-FAMILY
\*------------------------------------*/
/*------------------------------------*\
  #FONT-SIZE
\*------------------------------------*/
/*------------------------------------*\
  #TRANSITION
\*------------------------------------*/
/**
* CONTENTS
*
* BASE
*
* ABOUT
*
* PROJECT-LIST
*
* DETAIL-PAGE
*
* DETAIL-HEADER
*
* DETAIL-BODY
*
* GO-TO-HOMEPAGE
*/
/*------------------------------------*\
 #BASE
\*------------------------------------*/
html {
  height: 100%;
  font-size: 10px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }

*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
          box-sizing: inherit; }

body {
  color: #303347;
  font-family: "Libre Franklin", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  -webkit-text-decoration-skip: ink;
          text-decoration-skip: ink;
  text-decoration-skip-ink: auto;
  background: #eee3df; }

h1 {
  font-weight: 800; }

h2,
h3,
h4,
h5,
h6,
strong {
  font-weight: 700; }

p {
  margin: 0 0 1em;
  font-size: calc( 1.05rem + .8vw + .4vh); }

a {
  color: #303347;
  text-decoration: none;
  border-bottom: 0.1rem dotted #303347; }
  a:hover {
    border-style: solid; }
  a:focus {
    outline: 0;
    color: #fff;
    text-shadow: none;
    background-color: #303347;
    border: none;
    border-radius: .2rem; }
  a:active {
    color: #3b3d4e;
    background: none;
    border: none; }

img {
  max-width: 100%;
  height: auto; }
  img.inline {
    vertical-align: text-top;
    margin: 0 .3em 0 .2em; }

.eyebrow {
  -webkit-box-ordinal-group: 0;
      -ms-flex-order: -1;
          order: -1;
  text-transform: uppercase;
  letter-spacing: .2em; }

.ampersand {
  display: inline-block;
  padding-right: .1em;
  font-family: Baskerville, Palatino, "Times New Roman", serif;
  font-size: 1.3em;
  font-style: italic;
  font-weight: 500;
  -webkit-transform: translateY(0.08em);
          transform: translateY(0.08em); }

.flex-container {
  position: relative;
  display: block;
  margin: 0 auto;
  z-index: 2; }

.text-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column wrap;
          flex-flow: column wrap;
  max-width: 58em;
  margin: 0 auto 1.5em; }

@media screen and (min-width: 32em) {
  p {
    font-size: 1.8rem; } }

@media screen and (min-width: 63em) {
  body {
    background: #fbf9f8;
    border: 1.2em solid #fbf9f8; }
    body > * {
      background: #eee3df; }
  .flex-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
        justify-content: space-around;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    max-width: 130em; }
    .flex-container.reverse {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: reverse;
          -ms-flex-direction: row-reverse;
              flex-direction: row-reverse; } }

/*------------------------------------*\
 #ABOUT
\*------------------------------------*/
#about {
  position: relative;
  width: 100%;
  margin: 0;
  padding: 2.5em 2rem 0;
  text-shadow: 0.1rem 0.1rem 0 rgba(255, 255, 255, 0.2);
  overflow: hidden; }
  #about h1 {
    margin-bottom: .5em;
    font-size: calc( 2.2rem + .8vw + .4vw);
    line-height: 1.4; }
  #about strong {
    display: block;
    margin-bottom: 1em;
    font-size: calc( 1.25rem + .8vw + .4vw); }
  #about .download {
    font-weight: 700; }
    #about .download small {
      font-size: 88.88%;
      font-weight: 400; }
      #about .download small:before {
        content: "[";
        padding-left: .1em;
        padding-right: .2em; }
      #about .download small:after {
        content: "]";
        padding-left: .2em;
        padding-right: 0; }
  #about .portrait {
    display: block;
    width: 85%;
    max-width: 50em;
    height: 100%;
    margin: 0 auto; }
  @media screen and (min-width: 32em) {
    #about h1 {
      font-size: 3.2rem; }
    #about strong {
      font-size: 2rem; } }
  @media screen and (min-width: 63em) {
    #about .text-container {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      width: 49%;
      max-width: 50em;
      margin: 0 auto; }
    #about .portrait {
      -ms-flex-item-align: end;
          align-self: flex-end;
      width: 52%;
      max-width: 60em;
      margin-top: 4em;
      margin-left: -6%; } }
  @media screen and (min-width: 80em) {
    #about .portrait {
      width: 55%;
      margin-left: -2%; } }
  @media screen and (min-width: 93em) {
    #about .text-container {
      margin: 0 1% 0 auto; }
    #about .portrait {
      margin-left: -1%; } }

/*------------------------------------*\
 #PROJECT-LIST
\*------------------------------------*/
nav {
  color: #fff;
  text-shadow: 0 0 0.5em rgba(0, 0, 0, 0.3); }
  nav li {
    position: relative;
    padding: 3rem 2rem 0;
    background-color: #303347;
    overflow: hidden; }
    nav li:after {
      content: '';
      position: absolute;
      top: -5%;
      left: -5%;
      width: 110%;
      height: 110%;
      background-position: center center;
      background-size: cover;
      -webkit-transition: top 2s cubic-bezier(0, 0.4, 0.6, 1), left 2s cubic-bezier(0, 0.4, 0.6, 1), width 2s cubic-bezier(0, 0.4, 0.6, 1), height 2s cubic-bezier(0, 0.4, 0.6, 1);
      transition: top 2s cubic-bezier(0, 0.4, 0.6, 1), left 2s cubic-bezier(0, 0.4, 0.6, 1), width 2s cubic-bezier(0, 0.4, 0.6, 1), height 2s cubic-bezier(0, 0.4, 0.6, 1);
      z-index: 1; }
    nav li:hover:after {
      top: -15%;
      left: -15%;
      width: 130%;
      height: 130%; }
    nav li.bettter:after {
      background-image: url("../bettter/bettter-blur.jpg"); }
    nav li.syracuse-calendar:after {
      background-image: url("../syracuse-calendar/syracuse-calendar-blur.jpg"); }
    nav li.syracuse-cobalt:after {
      background-image: url("../syracuse-cobalt/syracuse-cobalt-blur.jpg"); }
    nav li.syracuse-edu:after {
      background-image: url("../syracuse-edu/syracuse-edu-blur.jpg"); }
  nav .text-container {
    max-width: 42em;
    margin-top: 3em;
    color: #fff;
    text-align: center;
    border: none; }
    nav .text-container:focus {
      background: none; }
  nav a {
    color: inherit; }
    nav a:hover h3 {
      border-color: rgba(255, 255, 255, 0.55); }
    nav a:focus h3 {
      border-color: #fff; }
    nav a:active h3 {
      border-color: transparent;
      -webkit-transition: none;
      transition: none; }
  nav h3 {
    -ms-flex-item-align: center;
        align-self: center;
    margin: 0 0 .5em;
    font-size: calc( 2.2rem + .8vw + .4vw);
    line-height: 1.25;
    text-shadow: 0 0 1em rgba(0, 0, 0, 0.3);
    border-bottom: .2rem solid transparent;
    -webkit-transition: border-color 0.2s cubic-bezier(0, 0.4, 0.6, 1);
    transition: border-color 0.2s cubic-bezier(0, 0.4, 0.6, 1); }
  nav .eyebrow {
    margin-bottom: 1.2em;
    font-size: calc( 1.1rem + .2vw + .1vw); }
  nav .image-container {
    border: 0; }
    nav .image-container:focus {
      background: none; }
    nav .image-container img {
      vertical-align: bottom;
      border-top-left-radius: .4rem;
      border-top-right-radius: .4rem; }
      nav .image-container img.loaded {
        border: 0.1rem solid rgba(0, 0, 0, 0.4);
        border-bottom-width: 0;
        -webkit-box-shadow: 0 0 1.3em 1.3em rgba(0, 0, 0, 0.1);
                box-shadow: 0 0 1.3em 1.3em rgba(0, 0, 0, 0.1); }
  @media screen and (min-width: 32em) {
    nav h3 {
      font-size: 3.2rem; }
    nav .eyebrow {
      font-size: 1.25rem; } }
  @media screen and (min-width: 63em) {
    nav li {
      height: 45vw;
      max-height: 60em; }
      nav li.syracuse-calendar p {
        max-width: 21.8em; }
      nav li.cobalt p {
        max-width: 20em; }
    nav .flex-container {
      max-width: 160em;
      height: 100%; }
    nav .text-container {
      width: 42%;
      margin: 2em 6%;
      text-align: left; }
    nav h3 {
      -ms-flex-item-align: start;
          align-self: flex-start; }
    nav .image-container {
      -ms-flex-item-align: end;
          align-self: flex-end;
      width: 70%;
      max-width: 85em;
      margin: 0 -11% 0 auto; } }
  @media screen and (min-width: 93em) {
    nav .text-container {
      width: 45%;
      margin-left: 10%; }
    nav .image-container {
      width: 64%;
      margin-right: 0; } }

/**
* CONTENTS
*
* BANNER-IMAGE
*
* ARTICLE-DETAIL
*
* FIGURES-AND-CAPTIONS
*
* OVERFLOW SCROLLING
*
* GO-TO-HOMEPAGE
*/
/*------------------------------------*\
 #BANNER-IMAGE
\*------------------------------------*/
.banner-container {
  position: relative;
  margin: 0 -2rem;
  padding: 2rem 2rem 0;
  background-color: #303347;
  background-position: center center;
  background-size: cover;
  overflow: hidden; }
  .banner-container img {
    display: block;
    width: 100%;
    max-width: 71rem;
    margin: 0 auto;
    border: 0.1rem solid rgba(0, 0, 0, 0.4);
    border-bottom-width: 0;
    border-radius: 0;
    border-top-left-radius: .3rem;
    border-top-right-radius: .3rem;
    -webkit-box-shadow: 0 0 2em 2em rgba(0, 0, 0, 0.1);
            box-shadow: 0 0 2em 2em rgba(0, 0, 0, 0.1); }
  @media screen and (min-width: 63em) {
    .banner-container {
      padding: calc( 1em + 3vw + 2vh) calc( 1em + 3vw + 2vh) 0; }
      .banner-container img {
        max-width: 120em;
        border-top-left-radius: .4rem;
        border-top-right-radius: .4rem; } }
  .banner-container.bettter {
    background-image: url("../bettter/bettter-blur.jpg"); }
  .banner-container.syracuse-calendar {
    background-image: url("../syracuse-calendar/syracuse-calendar-blur.jpg"); }
  .banner-container.syracuse-cobalt {
    background-image: url("../syracuse-cobalt/syracuse-cobalt-blur.jpg"); }
  .banner-container.syracuse-edu {
    background-image: url("../syracuse-edu/syracuse-edu-blur.jpg"); }
  .banner-container:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: .1rem;
    background: rgba(0, 0, 0, 0.3); }

/*------------------------------------*\
 #ARTICLE-DETAIL
\*------------------------------------*/
article {
  padding: 0 2rem 6em;
  text-shadow: 0.1rem 0.1rem 0 rgba(255, 255, 255, 0.2); }
  article .text-container {
    max-width: 64em;
    margin-top: 2.5em; }
  article .eyebrow {
    margin-bottom: .5em;
    font-size: calc( 1.2rem + .2vw + .1vw);
    letter-spacing: .15rem; }
  article h1 {
    margin-bottom: .7em;
    font-size: calc( 2.6rem + .8vw + .4vw);
    line-height: 1.4; }
  article h2 {
    margin: .4em 0 .7em;
    font-size: calc( 1.7rem + .8vw + .4vw); }
  article ol {
    list-style: decimal;
    margin-bottom: 1em;
    padding-left: 1em;
    font-size: calc( 1.05rem + .8vw + .4vh); }
  article li {
    margin-bottom: .7em;
    padding-left: .35em; }
  @media screen and (min-width: 32em) {
    article .text-container {
      margin-top: 4em; }
    article .eyebrow {
      font-size: 1.5rem; }
    article h1 {
      font-size: 3.8rem; }
    article h2 {
      font-size: 2.5rem; }
    article header p {
      font-size: 1.9rem; }
    article ol {
      font-size: 1.8rem;
      -webkit-column-count: 2;
         -moz-column-count: 2;
              column-count: 2; }
    article li {
      -webkit-column-break-inside: avoid;
         page-break-inside: avoid;
              break-inside: avoid; } }

/*------------------------------------*\
 #FIGURES-AND-CAPTIONS
\*------------------------------------*/
figure {
  margin: 3em 0 5em;
  padding-top: 2rem;
  -webkit-overflow-scrolling: touch; }
  figure .image-container {
    position: relative;
    display: table;
    margin: 0 auto 2em; }
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      figure .image-container {
        display: block; } }
    figure .image-container.outline.loaded:after {
      -webkit-box-shadow: inset 0 0 0 0.1rem rgba(0, 0, 0, 0.3);
              box-shadow: inset 0 0 0 0.1rem rgba(0, 0, 0, 0.3);
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: .4rem;
      box-shadow: inset 0 0 0 0.1rem rgba(0, 0, 0, 0.3); }
  figure img {
    display: block;
    border-radius: .4rem; }

figcaption {
  position: relative;
  max-width: 62rem;
  margin: 1em auto 2em;
  padding-left: 3rem;
  font-size: calc( 1.05rem + .8vw + .4vh);
  white-space: normal;
  background: url("../img/arrow.svg") no-repeat left 0.35em;
  background-size: .8em; }

@media screen and (min-width: 32em) {
  figcaption {
    font-size: 1.8rem; } }

/*------------------------------------*\
  #OVERFLOW-SCROLLING
\*------------------------------------*/
.scroll-container {
  display: block;
  margin: 0 -2rem;
  padding-left: 2rem;
  text-align: center;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap; }
  .scroll-container::-webkit-scrollbar {
    display: none !important; }
  .scroll-container .image-container {
    position: relative;
    display: inline-block;
    width: 85%;
    max-width: 80em;
    margin-right: 2rem;
    vertical-align: top; }
    @media screen and (min-width: 45em) {
      .scroll-container .image-container:first-child:nth-last-child(2),
      .scroll-container .image-container:first-child:nth-last-child(2) ~ .image-container {
        width: 60%; } }
    @media screen and (min-width: 72em) {
      .scroll-container .image-container:first-child:nth-last-child(2),
      .scroll-container .image-container:first-child:nth-last-child(2) ~ .image-container {
        width: calc( 50% - 2.5rem); } }
  .scroll-container.mobile .image-container {
    min-width: 18em;
    width: 57%;
    max-width: 40em; }
    @media screen and (min-width: 45em) {
      .scroll-container.mobile .image-container {
        width: 37%; } }
    @media screen and (min-width: 63em) {
      .scroll-container.mobile .image-container {
        width: calc( 33.333% - 2.5rem); } }
    .scroll-container.mobile .image-container:first-child:nth-last-child(4),
    .scroll-container.mobile .image-container:first-child:nth-last-child(4) ~ .image-container {
      max-width: 37em; }
      @media screen and (min-width: 72em) {
        .scroll-container.mobile .image-container:first-child:nth-last-child(4),
        .scroll-container.mobile .image-container:first-child:nth-last-child(4) ~ .image-container {
          width: calc( 25% - 2.5rem); } }
  .scroll-container.deck .image-container:not(.loaded) {
    height: 0; }
  @media screen and (min-width: 63em) {
    .scroll-container.deck .image-container {
      width: 44%;
      max-width: 70em; } }

/*------------------------------------*\
  #GO-TO-HOMEPAGE
\*------------------------------------*/
#go-to-homepage {
  position: fixed;
  bottom: 1.8rem;
  left: 1.8rem;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 2.6em;
  height: 2.6em;
  font-size: 2.5rem;
  line-height: 1;
  text-align: center;
  background: #fbf9f8;
  border: 1em solid #fbf9f8;
  border-width: .6em .8em;
  border-radius: 9rem;
  -webkit-box-shadow: 0 0 1em rgba(0, 0, 0, 0.15);
          box-shadow: 0 0 1em rgba(0, 0, 0, 0.15);
  white-space: nowrap;
  z-index: 2; }
  #go-to-homepage:focus, #go-to-homepage.page-bottom {
    width: auto;
    color: #303347;
    -webkit-box-shadow: 0 0 1em rgba(0, 0, 0, 0.15);
            box-shadow: 0 0 1em rgba(0, 0, 0, 0.15);
    overflow: hidden; }
  #go-to-homepage:active {
    background: #fbf9f8; }
  #go-to-homepage img {
    position: relative;
    display: inline;
    width: 2.5rem;
    height: 2.5rem;
    vertical-align: middle;
    z-index: 2; }
  #go-to-homepage strong {
    display: inline-block;
    margin-left: .8em;
    color: #fbf9f8;
    font-size: 75%;
    font-weight: 700;
    text-indent: -99rem;
    border-bottom: .1rem dotted transparent;
    -webkit-transform: translateY(0.15rem);
            transform: translateY(0.15rem);
    -webkit-transition: text-indent 0.1s cubic-bezier(0, 0.4, 0.6, 1);
    transition: text-indent 0.1s cubic-bezier(0, 0.4, 0.6, 1); }
  #go-to-homepage:focus strong,
  #go-to-homepage.page-bottom strong {
    position: relative;
    color: #303347;
    text-indent: 0; }
  #go-to-homepage.page-bottom:active strong {
    border-color: transparent; }
  @media screen and (min-width: 63em) {
    #go-to-homepage {
      left: 3rem; }
      #go-to-homepage.page-bottom {
        bottom: 3rem; } }
  @media (hover: hover) and (pointer: fine) {
    #go-to-homepage:hover {
      width: auto;
      color: #303347;
      -webkit-box-shadow: 0 0 1em rgba(0, 0, 0, 0.15);
              box-shadow: 0 0 1em rgba(0, 0, 0, 0.15);
      overflow: hidden; }
      #go-to-homepage:hover strong {
        position: relative;
        color: #303347;
        text-indent: 0; }
    #go-to-homepage.page-bottom:hover strong {
      border-color: #303347; } }

/**
* CONTENTS
*
* ACCESSIBILITY
*
* LAYOUT
*/
/*------------------------------------*\
  #ACCESSIBILITY
\*------------------------------------*/
.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

/*------------------------------------*\
  #LAYOUT
\*------------------------------------*/
