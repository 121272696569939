/**
* CONTENTS
*
* BASE
*
* ABOUT
*
* PROJECT-LIST
*
* DETAIL-PAGE
*
* DETAIL-HEADER
*
* DETAIL-BODY
*
* GO-TO-HOMEPAGE
*/





/*------------------------------------*\
 #BASE
\*------------------------------------*/


html {
  height: 100%;
  font-size: 10px;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  color: $color-slate;
  font-family: $font-sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  text-decoration-skip: ink;
  text-decoration-skip-ink: auto;
  background: $color-beige;
}

h1 {
  font-weight: 800;
}

h2, 
h3,
h4,
h5,
h6,
strong {
  font-weight: 700;
}

p {
  margin: 0 0 1em;
  font-size: $font-size-p-sm;
}

a {
  color: $color-slate;
  text-decoration: none;
  border-bottom: .1rem dotted $color-slate;

  &:hover {
    border-style: solid;
  }

  &:focus {
    outline: 0;
    color: $color-white;
    text-shadow: none;
    background-color: $color-slate;
    border: none;
    border-radius: .2rem;
  }

  &:active {
    color: #3b3d4e;
    background: none;
    border: none;
  }

}

img {
  max-width: 100%;
  height: auto;

  &.inline {
    vertical-align: text-top;
    margin: 0 .3em 0 .2em;
  }

}

.eyebrow {
  order: -1;
  text-transform: uppercase;
  letter-spacing: .2em;
}

.ampersand {
  display: inline-block;
  padding-right: .1em;
  font-family: $font-serif;
  font-size: 1.3em;
  font-style: italic;
  font-weight: 500;
  transform: translateY( .08em );
}

.flex-container {
  position: relative;
  display: block;
  margin: 0 auto;
  z-index: 2;
}

.text-container {
  display: flex;
  flex-flow: column wrap;
  max-width: 58em;
  margin: 0 auto 1.5em;
}

@media screen and (min-width: $breakpoint-xs) {

  p {
    font-size: $font-size-p-md;
  }

}

@media screen and (min-width: $breakpoint-md) {

  // Make the background and border colors match
  body {
    background: $color-off-white;
    border: 1.2em solid $color-off-white;

    > * {
      background: $color-beige;
    }

  }

  .flex-container {
    display: flex;
    justify-content: space-around; // alignment along the horizontal axis
    align-items: center;  //alignment along the vertical axis
    max-width: 130em;

    &.reverse {
      flex-direction: row-reverse;
    }

  }

}





/*------------------------------------*\
 #ABOUT
\*------------------------------------*/


#about {
  position: relative;
  width: 100%;
  margin: 0;
  padding: 2.5em 2rem 0;
  text-shadow: .1rem .1rem 0 rgba( 255, 255, 255, .2 );
  overflow: hidden;

  h1 {
    margin-bottom: .5em;
    font-size: $font-size-home-h1-sm;
    line-height: 1.4;
  }

  strong {
    display: block;
    margin-bottom: 1em;
    font-size: $font-size-home-h2-sm;
  }

  .download {
    font-weight: 700;

    small {
      font-size: 88.88%;
      font-weight: 400;

      &:before {
        content: "[";
        padding-left: .1em;
        padding-right: .2em;
      }

      &:after {
        content: "]";
        padding-left: .2em;
        padding-right: 0;
      }

    }

  }

  .portrait {
    display: block;
    width: 85%;
    max-width: 50em;
    height: 100%;
    margin: 0 auto;
  }

  @media screen and (min-width: $breakpoint-xs) {

    h1 {
      font-size: $font-size-home-h1-md;
    }

    strong {
      font-size: $font-size-home-h2-md;
    }

  }

  @media screen and (min-width: $breakpoint-md) {

    .text-container {
      display: flex;
      width: 49%;
      max-width: 50em;
      margin: 0 auto;
    }

    .portrait {
      align-self: flex-end;
      width: 52%;
      max-width: 60em;
      margin-top: 4em;
      margin-left: -6%;
    }

  }

  @media screen and (min-width: $breakpoint-xl) {

    .portrait {
    	width: 55%;
      margin-left: -2%;
    }

  }

  @media screen and (min-width: $breakpoint-xxl) {

    .text-container {
      margin: 0 1% 0 auto;
    }

    .portrait {
      margin-left: -1%;
    }

  }

}





/*------------------------------------*\
 #PROJECT-LIST
\*------------------------------------*/


nav {
  color: $color-white;
  text-shadow: 0 0 .5em rgba( 0, 0, 0, .3 );

	li {
		position: relative;
		padding: 3rem 2rem 0;
		background-color: $color-slate;
		overflow: hidden;

		&:after {
			content: '';
		  position: absolute;
		  top: -5%;
		  left: -5%;
		  width: 110%;
		  height: 110%;
		  background-position: center center;
		  background-size: cover;
		  transition: top 2s $transition-bezier,
		              left 2s $transition-bezier,
		              width 2s $transition-bezier,
		              height 2s $transition-bezier;
		  z-index: 1;
		}

		&:hover:after {
		  top: -15%;
		  left: -15%;
		  width: 130%;
		  height: 130%;
		}

    &.bettter:after {
      background-image: url( '../bettter/bettter-blur.jpg' );
    }

    &.syracuse-calendar:after {
      background-image: url( '../syracuse-calendar/syracuse-calendar-blur.jpg' );
    }

    &.syracuse-cobalt:after {
      background-image: url( '../syracuse-cobalt/syracuse-cobalt-blur.jpg' );
    }

    &.syracuse-edu:after {
      background-image: url( '../syracuse-edu/syracuse-edu-blur.jpg' );
    }

	}

	.text-container {
	  max-width: 42em;
	  margin-top: 3em;
	  color: $color-white;
	  text-align: center;
	  border: none;

	  &:focus {
	    background: none;
	  }

	}

	a {
		color: inherit;

	  &:hover h3 {
	    border-color: rgba( 255, 255, 255, .55 );
	  }

	  &:focus h3 {
	    border-color: $color-white;
	  }

	  &:active h3 {
	    border-color: transparent;
	    transition: none;
	  }

	}

  h3 {
  	align-self: center;
  	margin: 0 0 .5em;
    font-size: $font-size-home-h1-sm;
    line-height: 1.25;
    text-shadow: 0 0 1em rgba( 0, 0, 0, .3 );
    border-bottom: .2rem solid transparent;
    transition: border-color .2s $transition-bezier;
  }

  .eyebrow {
  	margin-bottom: 1.2em;
    font-size: $font-size-home-eyebrow-sm;
  }

  .image-container {
    border: 0;

    &:focus {
      background: none;
    }

    img {
      vertical-align: bottom;
      border-top-left-radius: .4rem;
      border-top-right-radius: .4rem;

      &.loaded {
        border: .1rem solid rgba( 0, 0, 0, .4 );
        border-bottom-width: 0;
        box-shadow: 0 0 1.3em 1.3em rgba( 0, 0, 0, .1 );
      }

    }

  }

  @media screen and (min-width: $breakpoint-xs) {

    h3 {
      font-size: $font-size-home-h1-md;
    }

    .eyebrow {
      font-size: $font-size-home-eyebrow-md;
    }

  }

  @media screen and (min-width: $breakpoint-md) {

  	li {
  	  height: 45vw;
  	  max-height: 60em;

      // Fix where the words land for this list item
      &.syracuse-calendar p {
        max-width: 21.8em;
      }
      
      // Fix where the words land for this list item
      &.cobalt p {
        max-width: 20em; 
      }

  	}

    .flex-container {
      max-width: 160em;
      height: 100%;
    }

  	.text-container {
  	  width: 42%;
  	  margin: 2em 6%;
  	  text-align: left;
  	}

    h3 {
      align-self: flex-start;
    }

    .image-container {
      align-self: flex-end;
      width: 70%;
      max-width: 85em;
      margin: 0 -11% 0 auto;
    }

  }

  @media screen and (min-width: $breakpoint-xxl) {

    .text-container {
      width: 45%;
      margin-left: 10%;
    }

    .image-container {
      width: 64%;
      margin-right: 0;
    }

  }

}
